import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
} from '@angular/router';
import { getCustomFlattenedPermissionName, getFlattenedPermissionName, NavigationArea, NavigationGuard, NavigationGuardData, NavigationPermission } from '@skykick/core';
import { CustomPermissions } from 'src/app/utilities/custom-permissions.util';
import {
  AddCustomerComponent,
  CustomersComponent,
  EditCustomerComponent,
} from './components/customers';
import {
  AddEditMemberFormComponent,
} from './components/members/add-edit-member-form/add-edit-member-form.component';
import { MembersComponent } from './components/members/members.component';
import { UsersComponent } from './components/users.component';

export const USERS_ROUTES_VIEW_PERMISSIONS = [
  getCustomFlattenedPermissionName(
    CustomPermissions.users,
    NavigationPermission.Read
  )
];

export const USERS_ROUTES_EDIT_PERMISSIONS = [
  getCustomFlattenedPermissionName(
    CustomPermissions.users,
    NavigationPermission.Admin
  )
];

export const CUSTOMERS_ROUTES_PERMISSIONS = [
  getFlattenedPermissionName(
    NavigationArea.CloudBackup,
    NavigationPermission.FullAccess
  ),
  getFlattenedPermissionName(
    NavigationArea.Migrations,
    NavigationPermission.FullAccess
  )
];

export const USERS_ROUTES: Routes = [
  {
    path: 'users',
    redirectTo: 'users/members',
    pathMatch: 'prefix',
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [NavigationGuard],
    data: {
      navigationGuardData: {
        mandatoryPermissions: USERS_ROUTES_VIEW_PERMISSIONS,
        isPrimaryNavigation: true,
      } as NavigationGuardData,
    },
    children: [
      {
        path: 'members',
        component: MembersComponent,
      },
      {
        path: 'members/add',
        component: AddEditMemberFormComponent,
        canActivate: [NavigationGuard],
        data: {
          navigationGuardData: {
            mandatoryPermissions: USERS_ROUTES_EDIT_PERMISSIONS,
            permissionAbsentRedirect: 'settings',
          } as NavigationGuardData,
        },
      },
      {
        path: 'members/:memberId/edit',
        component: AddEditMemberFormComponent,
        canActivate: [NavigationGuard],
        data: {
          navigationGuardData: {
            mandatoryPermissions: USERS_ROUTES_EDIT_PERMISSIONS,
            permissionAbsentRedirect: 'settings',
          } as NavigationGuardData,
        },
      },
      {
        path: 'customers',
        component: CustomersComponent,
        canActivate: [NavigationGuard],
        data: {
          navigationGuardData: {
            optionalPermissions: CUSTOMERS_ROUTES_PERMISSIONS,
            permissionAbsentRedirect: 'settings',
          } as NavigationGuardData,
        },
      },
      {
        path: 'customers/add',
        component: AddCustomerComponent,
        canActivate: [NavigationGuard],
        data: {
          navigationGuardData: {
            optionalPermissions: CUSTOMERS_ROUTES_PERMISSIONS,
            permissionAbsentRedirect: 'settings',
          } as NavigationGuardData,
        },
      },
      {
        path: 'customers/:customerId/edit',
        component: EditCustomerComponent,
        canActivate: [NavigationGuard],
        data: {
          navigationGuardData: {
            optionalPermissions: CUSTOMERS_ROUTES_PERMISSIONS,
            permissionAbsentRedirect: 'settings',
          } as NavigationGuardData,
        },
      },
    ],
  },
];

@NgModule({
    imports: [RouterModule.forChild(USERS_ROUTES)],
    exports: [RouterModule]
})
export class UsersRoutingModule { }
