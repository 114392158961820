import { Component, HostBinding, OnInit } from '@angular/core';
import { AbstractUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { NOTIFICATIONS_ROUTES_PERMISSIONS } from '../notifications/notifications-routing.module';
import { PAYMENT_ROUTES_PERMISSIONS } from '../payment/payment-routing.module';
import { USERS_ROUTES_EDIT_PERMISSIONS, USERS_ROUTES_VIEW_PERMISSIONS } from '../users/users-routing.module';
import { INTEGRATIONS_ROUTES_PERMISSIONS } from '../integrations/integrations-routing.module';
import { ACCOUNT_ROUTES_PERMISSIONS } from '../account/account-routing.module';
import { PROFILE_ROUTES_PERMISSIONS } from '../settings-routing.module';

@Component({
  selector: 'sk-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.scss'],
})
export class LeftNavComponent implements OnInit {
  @HostBinding('class') classes = 'sk-panel-left sk-panel-primary panel-xs-1';

  hasAccessToProfiles: boolean;
  hasAccessToAccount: boolean;
  hasAccessToUsers: boolean;
  hasAccessToPayment: boolean;
  hasAccessToIntegrations: boolean;
  hasAccessToNotifications: boolean;

  constructor(private abstractUserProvider: AbstractUserProvider) {}

  ngOnInit(): void {
    const currentUser = this.abstractUserProvider.getCurrentUser();

    // checking if user has access to any of the tab (based on the setup from *-routing.module.ts)
    this.hasAccessToProfiles = PROFILE_ROUTES_PERMISSIONS.some(permission => currentUser.hasPermission(permission));
    this.hasAccessToAccount = ACCOUNT_ROUTES_PERMISSIONS.some(permission => currentUser.hasPermission(permission));
    this.hasAccessToUsers = USERS_ROUTES_VIEW_PERMISSIONS.some(permission => currentUser.hasPermission(permission));
    this.hasAccessToPayment = PAYMENT_ROUTES_PERMISSIONS.some(permission => currentUser.hasPermission(permission));
    this.hasAccessToIntegrations = INTEGRATIONS_ROUTES_PERMISSIONS.some(permission => currentUser.hasPermission(permission));
    this.hasAccessToNotifications = NOTIFICATIONS_ROUTES_PERMISSIONS.some(permission => currentUser.hasPermission(permission));
  }
}
