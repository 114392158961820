import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { getCustomFlattenedPermissionName, getFlattenedPermissionName, NavigationArea, NavigationGuard, NavigationGuardData, NavigationPermission } from '@skykick/core';
import { CustomPermissions } from 'src/app/utilities/custom-permissions.util';
import { PaymentComponent } from './payment/payment.component';

export const PAYMENT_ROUTES_PERMISSIONS = [
  getCustomFlattenedPermissionName(
    CustomPermissions.payment,
    NavigationPermission.Read
  ),
  getFlattenedPermissionName(
    NavigationArea.Billing,
    NavigationPermission.FullAccess
  )
];

export const PAYMENT_ROUTES: Routes = [
  {
    path: 'payment',
    component: PaymentComponent,
    canActivate: [NavigationGuard],
    data: {
      navigationGuardData: {
        optionalPermissions: PAYMENT_ROUTES_PERMISSIONS,
        permissionAbsentRedirect: 'settings'
      } as NavigationGuardData
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(PAYMENT_ROUTES)],
  exports: [RouterModule]
})
export class PaymentRoutingModule { }
