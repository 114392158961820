import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { getCustomFlattenedPermissionName, getFlattenedPermissionName, NavigationArea, NavigationGuard, NavigationGuardData, NavigationPermission } from '@skykick/core';
import { CustomPermissions } from '../utilities/custom-permissions.util';
import { ACCOUNT_ROUTES } from './account/account-routing.module';
import { INTEGRATIONS_ROUTES } from './integrations/integrations-routing.module';
import { NOTIFICATIONS_ROUTES } from './notifications/notifications-routing.module';
import { PAYMENT_ROUTES } from './payment/payment-routing.module';
import { SettingsComponent } from './settings.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { USERS_ROUTES } from './users/users-routing.module';

export const PROFILE_ROUTES_PERMISSIONS = [
  getCustomFlattenedPermissionName(
    CustomPermissions.profile,
    NavigationPermission.Read
  )
];

export const SETTINGS_ROUTES: Routes = [
  {
    path: 'settings',
    redirectTo: 'settings/users/members',
    pathMatch: 'prefix',
  },
  {
    path: 'partner/admin/user-profile',
    redirectTo: 'settings/users/members',
    pathMatch: 'prefix',
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [NavigationGuard],
    data: {
      navigationGuardData: {
        mandatoryPermissions: [
          getFlattenedPermissionName(
            NavigationArea.Admin,
            NavigationPermission.Read
          ),
        ],
        isPrimaryNavigation: true
      } as NavigationGuardData,
      SkNavigationArea: NavigationArea.Admin,
    },
    children: [
      ...USERS_ROUTES,
      ...NOTIFICATIONS_ROUTES,
      ...ACCOUNT_ROUTES,
      ...PAYMENT_ROUTES,
      ...INTEGRATIONS_ROUTES,
      {
        path: 'profile',
        canActivate: [NavigationGuard],
        component: UserProfileComponent,
        data: {
          navigationGuardData: {
            mandatoryPermissions: PROFILE_ROUTES_PERMISSIONS,
            permissionAbsentRedirect: 'settings'
          } as NavigationGuardData
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(SETTINGS_ROUTES)],
  exports: [RouterModule],
})
export class SettingsRoutingModule {}
